<template>
  <div class="CustomFormRecordList">
    <list
      v-if="show"
      ref="list"
      :extraParams="extraParams"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :searchBefore="searchBefore"
      :headers="headers"
      isMultiSelect
    >
      <template #headerSlot>
        <v-button v-if="userType == 101 && (publishType == 1 || publishType == 3)" text="新增" permission="resultAdd" @click="createData" />
        <v-button text="导出" permission="resultExport" @click="exportExcle" />
        <v-button text="返回" @click="previous" />
      </template>
      <template #searchSlot>
        <v-input label="填写人" v-model="searchParams['conditionFields'].inuserName" />
        <v-select2 label="所属项目" v-model="searchParams['conditionFields'].communityId" v-bind="communityParams" />

        <div v-for="(item, index) in conditionFields" :key="index">
          <v-input v-if="['input', 'username', 'userphone', 'userorg'].includes(item.type)" :label="item.name" v-model="searchParams['conditionFields'][item.model]" />
          <v-input-number v-if="['number', 'userage'].includes(item.type)" :label="item.name" v-model="searchParams['conditionFields'][item.model]" :controls="true" controls-position="right" />
          <v-select v-if="['radio', 'checkbox', 'select', 'usersex'].includes(item.type)" :label="item.name" v-model="searchParams['conditionFields'][item.model]" :options="item.options.options" />
          <v-datepicker v-if="['date'].includes(item.type)" :type="item.options.type == 'datetime' ? 'rangedatetimer' : 'rangedate'" :label="item.name" :startTime.sync="searchParams['conditionFields'][item.model + '_custom_start']" :endTime.sync="searchParams['conditionFields'][item.model + '_custom_end']" />
          <v-timepicker v-if="['time'].includes(item.type)" :label="item.name" :isRange="true" rangeSeparator="至" :startTime.sync="searchParams['conditionFields'][item.model + '_custom_start']" :endTime.sync="searchParams['conditionFields'][item.model + '_custom_end']" />
        </div>

        <v-select label="状态" v-model="searchParams['conditionFields'].approveStatus" :options="recordStatusOps" />
        <v-datepicker label="填写时间" type="rangedatetimer" :startTime.sync="searchParams['conditionFields'].beginTime" :endTime.sync="searchParams['conditionFields'].endTime" />
      </template>
      <template #operateSlot="scope">
        <v-button text="详情" type="text" @click="detailData(scope.row)" />
        <v-button v-if="userType == 101 && (approval == 0 || ((approval == 1 || approval == 2) && scope.row.approve_status == 3))" text="编辑" type="text" permission="resultEdit" @click="updateData(scope.row)" />
        <v-button text="审批" v-if="approval == 1 && scope.row.approve_status === 0" permission="approve" type="text" @click="approvalData(scope.row)" />
        <v-button text="审批" v-if="approval == 2" permission="approve" type="text" @click="flowWorkApprovalData(scope.row)" />
        <v-button text="删除" permission="resultDelete" type="text" @click="deleteData(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button text="批量审批" v-if="approval == 1" permission="approve" :disabled="scope.selectedData.ids.length === 0" @click="batchOperation(1, scope.selectedData)" />
        <v-button text="批量删除" permission="resultDelete" :disabled="scope.selectedData.ids.length === 0" @click="batchOperation(2, scope.selectedData)" />
      </template>
    </list>
    <el-dialog custom-class="approve-dialog"
      :title="approveTitle"
      width="750px"
      :visible.sync="approveVisible"
      @closed="approveDialogClosed"
    >
      <el-form ref="approve" :model="approvalParams" label-width="120px">
        <el-form-item label="审批结果：" required>
          <v-select v-model="approvalParams.approveStatus" :options="approveStatusOps" @change="approveStatusChange" />
        </el-form-item>
        <el-form-item label="审批意见：">
          <v-textarea v-model="approvalParams.approveRemarks" />
        </el-form-item>
        <div class="button-submit-container">
          <v-button text="提交" @click="submit" />
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import { exportListURL, queryRecodeListURL, queryColumnURL, approveRecodeURL, deleteRecodeURL, batchApproveRecodeURL, batchDeleteRecodeURL, queryDetailURL } from './api'
import { setRecordStatusOps, recordStatusMap, setApproveStatusOps, formResourceMap } from './map'

export default {
  name: 'CustomFormRecordList',
  data () {
    return {
      formName: '',
      publishType: undefined,
      approval: undefined,
      show: false,
      searchUrl: queryRecodeListURL,
      communityParams,
      recordStatusOps: setRecordStatusOps(1),
      approveVisible: false,
      approvalParams: {},
      approveTitle: '',
      approveStatusOps: setApproveStatusOps(),
      conditionFields: [],
      extraParams: {
        formId: undefined
      },
      searchParams: {
        conditionFields: {
          inuserName: '',
          communityId: '',
          beginTime: '',
          endTime: '',
          approveStatus: undefined
        }
      },
      headers: [
        {
          prop: 'inuser_name',
          label: '填写人'
        },
        {
          prop: 'community_name',
          label: '所属项目'
        },
        {
          prop: 'approveStatusText',
          label: '状态',
          formatter (row) {
            return recordStatusMap[row.approve_status]
          }
        },
        {
          prop: 'intime',
          label: '填写时间'
        }
      ]
    }
  },
  created () {
    this.handleData()
  },
  watch: {
    '$route': {
      async handler (newValue, oldValue) {
        this.initData()
        this.handleData()
      }
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    }
  },
  methods: {
    initData () {
      Object.assign(this.$data, this.$options.data())
    },
    async handleData () {
      const { source, formId } = this.$route.query
      this.extraParams.formId = formId
      await this.queryDetail()
      if (source === 'customFormList') {
        const breadcrumb = `表单记录 —《${this.formName}》`
        this.$setBreadcrumb(breadcrumb)
      }
      await this.queryColumn(formId)
      if (this.publishType == '2' || this.publishType == '3') { // eslint-disable-line
        this.handleHeaders()
      }
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
      this.resetApprovalParams()
    },
    async queryDetail () {
      const params = {
        id: this.extraParams.formId
      }
      const { status, data } = await this.$axios.get(queryDetailURL, { params })
      if (status === 100) {
        if (data) {
          const { name, publishType, approval } = data
          this.formName = name
          this.publishType = publishType
          this.approval = approval
        }
      }
    },
    searchBefore ({ params }) {
      const conditionFields = params.conditionFields
      const keys = Object.keys(conditionFields)
      if (keys && keys.length) {
        keys.forEach(key => {
          if (key.indexOf('_custom') !== -1) {
            const _key = key.split('_custom')[0]
            params.conditionFields[_key] = (params.conditionFields[_key + '_custom_start'] ? params.conditionFields[_key + '_custom_start'] : '') + ',' + (params.conditionFields[_key + '_custom_end'] ? params.conditionFields[_key + '_custom_end'] : '')
            delete params.conditionFields[_key + '_custom']
          }
        })
      }
      return true
    },
    resetApprovalParams () {
      this.approveTitle = ''
      this.approvalParams = {
        formId: undefined,
        // id: undefined,
        // ids: undefined,
        approveStatus: 1,
        approveRemarks: '已通过'
      }
    },
    async submit () {
      let params = this.approvalParams
      let url = ''
      if (params.id !== undefined) {
        url = approveRecodeURL
      } else if (params.ids !== undefined) {
        url = batchApproveRecodeURL
      }
      const { status } = await this.$axios.post(url, params)
      if (status === 100) {
        this.approveVisible = false
        this.$message.success('提交成功')
        this.$refs.list.searchData()
      }
    },
    approveDialogClosed () {
      this.resetApprovalParams()
    },
    handleHeaders () {
      const headers = [
        {
          prop: 'form_resource',
          label: '填写端口',
          formatter (row) {
            return formResourceMap[row.form_resource]
          }
        }
      ]
      this.headers.splice(1, 0, ...headers)
    },
    async queryColumn (id) {
      const params = {
        id
      }
      const { status, data } = await this.$axios.get(queryColumnURL, { params })
      if (status === 100) {
        const listFields = data.listFields ? JSON.parse(data.listFields) : []
        if (Array.isArray(listFields) && listFields.length) {
          const headers = []
          listFields.forEach(item => {
            if (item.tableModel === undefined) { // 过滤掉子表单要显示的表头
              if (['org', 'community', 'communityroom'].includes(item.type)) {
                headers.push({
                  prop: item.model + '_display',
                  label: item.name
                })
              } else {
                headers.push({
                  prop: item.model,
                  label: item.name
                })
              }
            }
          })
          this.headers.splice(2, 0, ...headers)
        }
        const conditionFields = data.conditionFields ? JSON.parse(data.conditionFields) : []
        if (Array.isArray(conditionFields) && conditionFields.length) {
          conditionFields.forEach(item => {
            if (['radio', 'checkbox', 'select', 'usersex'].includes(item.type)) {
              this.searchParams['conditionFields'][item.model] = undefined
              if (item.options && item.options.options) {
                item.options.options.length && item.options.options.forEach(option => {
                  option['text'] = option.label
                })
                item.options.options.unshift({
                  text: '全部',
                  value: undefined
                })
              }
            } else if (['time', 'date'].includes(item.type)) {
              this.searchParams['conditionFields'][item.model + '_custom'] = ''
              this.searchParams['conditionFields'][item.model + '_custom_start'] = ''
              this.searchParams['conditionFields'][item.model + '_custom_end'] = ''
            } else if (['number', 'userage'].includes(item.type)) {
              this.searchParams['conditionFields'][item.model] = undefined
            } else {
              this.searchParams['conditionFields'][item.model] = ''
            }
          })
          this.conditionFields = conditionFields
        }
      }
    },
    previous () {
      this.$router.go(-1)
    },
    createData () {
      this.$router.push({
        name: 'customFormRecordFormSubmit',
        query: {
          formId: this.extraParams.formId,
          formName: this.formName
        }
      })
    },
    detailData (row) {
      this.$router.push({
        name: 'customFormRecordForm',
        query: {
          formId: this.extraParams.formId,
          id: row.id,
          formName: this.formName
        }
      })
    },
    updateData (row) {
      this.$router.push({
        name: 'customFormRecordFormSubmit',
        query: {
          formId: this.extraParams.formId,
          formName: this.formName,
          id: row.id
        }
      })
    },
    approvalData (row) {
      this.approveTitle = `《${this.formName}》 > 审批`
      this.approvalParams['id'] = row.id
      this.approvalParams.formId = this.extraParams.formId
      this.approveVisible = true
    },
    flowWorkApprovalData (row) {
      this.$router.push({
        name: 'customFormRecordFormApproval',
        query: {
          processInstanceId: row.proc_inst_id,
          formName: this.formName
        }
      })
    },
    batchApprovalData (ids) {
      this.approveTitle = '批量审批'
      this.approvalParams['ids'] = ids
      this.approvalParams.formId = this.extraParams.formId
      this.approveVisible = true
    },
    approveStatusChange (val) {
      if (val === 1) {
        this.approvalParams.approveRemarks = '已通过'
      } else if (val === 2) {
        this.approvalParams.approveRemarks = '已拒绝'
      } else if (val === 3) {
        this.approvalParams.approveRemarks = '已驳回，请修改'
      }
    },
    async deleteData (row) {
      const result = await this.$confirm('确认删除？')
      if (result) {
        const formId = this.extraParams.formId
        const id = row.id
        const url = `${deleteRecodeURL}?formId=${formId}&id=${id}`
        const { status } = await this.$axios.post(url)
        if (status === 100) {
          this.$message.success('删除成功')
          this.$refs.list.searchData()
        }
      }
    },
    checkBatchData (selected, allowStatus, message) {
      const data = selected.data
      for (let i = 0; i < data.length; i++) {
        if (!allowStatus.includes(data[i].approve_status)) {
          this.$message({
            type: 'warning',
            message,
            center: true
          })
          return false
        }
      }
      return true
    },
    async batchOperation (type, selected) {
      const ids = selected.ids
      if (type === 1) {
        const allowStatus = [0]
        const message = '表单状态错误，无法批量审批'
        const checkResult = this.checkBatchData(selected, allowStatus, message)
        if (!checkResult) {
          return
        }
        this.batchApprovalData(ids)
      } else if (type === 2) {
        const result = await this.$confirm('确认删除？')
        if (result) {
          const formId = this.extraParams.formId
          const params = {
            ids,
            formId
          }
          const { status } = await this.$axios.post(batchDeleteRecodeURL, params)
          if (status === 100) {
            this.$message.success('操作成功')
            this.$refs.list.searchData()
          }
        }
      }
    },
    exportExcle () {
      let params = this.$refs.list.getSearchParams()
      this.$axios.get(exportListURL, params).then(res => {
        if (res.status === 100) {
          location.href = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.CustomFormRecordList {
  ::v-deep .approve-dialog {
    text-align: left;
    .button-submit-container {
      text-align: center;
    }
  }
}
</style>
