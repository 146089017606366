var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CustomFormRecordList" },
    [
      _vm.show
        ? _c("list", {
            ref: "list",
            attrs: {
              extraParams: _vm.extraParams,
              searchUrl: _vm.searchUrl,
              searchParams: _vm.searchParams,
              searchBefore: _vm.searchBefore,
              headers: _vm.headers,
              isMultiSelect: "",
            },
            on: {
              "update:searchParams": function ($event) {
                _vm.searchParams = $event
              },
              "update:search-params": function ($event) {
                _vm.searchParams = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "headerSlot",
                  fn: function () {
                    return [
                      _vm.userType == 101 &&
                      (_vm.publishType == 1 || _vm.publishType == 3)
                        ? _c("v-button", {
                            attrs: { text: "新增", permission: "resultAdd" },
                            on: { click: _vm.createData },
                          })
                        : _vm._e(),
                      _c("v-button", {
                        attrs: { text: "导出", permission: "resultExport" },
                        on: { click: _vm.exportExcle },
                      }),
                      _c("v-button", {
                        attrs: { text: "返回" },
                        on: { click: _vm.previous },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "searchSlot",
                  fn: function () {
                    return [
                      _c("v-input", {
                        attrs: { label: "填写人" },
                        model: {
                          value: _vm.searchParams["conditionFields"].inuserName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchParams["conditionFields"],
                              "inuserName",
                              $$v
                            )
                          },
                          expression:
                            "searchParams['conditionFields'].inuserName",
                        },
                      }),
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { label: "所属项目" },
                            model: {
                              value:
                                _vm.searchParams["conditionFields"].communityId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchParams["conditionFields"],
                                  "communityId",
                                  $$v
                                )
                              },
                              expression:
                                "searchParams['conditionFields'].communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                      _vm._l(_vm.conditionFields, function (item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            [
                              "input",
                              "username",
                              "userphone",
                              "userorg",
                            ].includes(item.type)
                              ? _c("v-input", {
                                  attrs: { label: item.name },
                                  model: {
                                    value:
                                      _vm.searchParams["conditionFields"][
                                        item.model
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchParams["conditionFields"],
                                        item.model,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "searchParams['conditionFields'][item.model]",
                                  },
                                })
                              : _vm._e(),
                            ["number", "userage"].includes(item.type)
                              ? _c("v-input-number", {
                                  attrs: {
                                    label: item.name,
                                    controls: true,
                                    "controls-position": "right",
                                  },
                                  model: {
                                    value:
                                      _vm.searchParams["conditionFields"][
                                        item.model
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchParams["conditionFields"],
                                        item.model,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "searchParams['conditionFields'][item.model]",
                                  },
                                })
                              : _vm._e(),
                            ["radio", "checkbox", "select", "usersex"].includes(
                              item.type
                            )
                              ? _c("v-select", {
                                  attrs: {
                                    label: item.name,
                                    options: item.options.options,
                                  },
                                  model: {
                                    value:
                                      _vm.searchParams["conditionFields"][
                                        item.model
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchParams["conditionFields"],
                                        item.model,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "searchParams['conditionFields'][item.model]",
                                  },
                                })
                              : _vm._e(),
                            ["date"].includes(item.type)
                              ? _c("v-datepicker", {
                                  attrs: {
                                    type:
                                      item.options.type == "datetime"
                                        ? "rangedatetimer"
                                        : "rangedate",
                                    label: item.name,
                                    startTime:
                                      _vm.searchParams["conditionFields"][
                                        item.model + "_custom_start"
                                      ],
                                    endTime:
                                      _vm.searchParams["conditionFields"][
                                        item.model + "_custom_end"
                                      ],
                                  },
                                  on: {
                                    "update:startTime": function ($event) {
                                      return _vm.$set(
                                        _vm.searchParams["conditionFields"],
                                        item.model + "_custom_start",
                                        $event
                                      )
                                    },
                                    "update:start-time": function ($event) {
                                      return _vm.$set(
                                        _vm.searchParams["conditionFields"],
                                        item.model + "_custom_start",
                                        $event
                                      )
                                    },
                                    "update:endTime": function ($event) {
                                      return _vm.$set(
                                        _vm.searchParams["conditionFields"],
                                        item.model + "_custom_end",
                                        $event
                                      )
                                    },
                                    "update:end-time": function ($event) {
                                      return _vm.$set(
                                        _vm.searchParams["conditionFields"],
                                        item.model + "_custom_end",
                                        $event
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            ["time"].includes(item.type)
                              ? _c("v-timepicker", {
                                  attrs: {
                                    label: item.name,
                                    isRange: true,
                                    rangeSeparator: "至",
                                    startTime:
                                      _vm.searchParams["conditionFields"][
                                        item.model + "_custom_start"
                                      ],
                                    endTime:
                                      _vm.searchParams["conditionFields"][
                                        item.model + "_custom_end"
                                      ],
                                  },
                                  on: {
                                    "update:startTime": function ($event) {
                                      return _vm.$set(
                                        _vm.searchParams["conditionFields"],
                                        item.model + "_custom_start",
                                        $event
                                      )
                                    },
                                    "update:start-time": function ($event) {
                                      return _vm.$set(
                                        _vm.searchParams["conditionFields"],
                                        item.model + "_custom_start",
                                        $event
                                      )
                                    },
                                    "update:endTime": function ($event) {
                                      return _vm.$set(
                                        _vm.searchParams["conditionFields"],
                                        item.model + "_custom_end",
                                        $event
                                      )
                                    },
                                    "update:end-time": function ($event) {
                                      return _vm.$set(
                                        _vm.searchParams["conditionFields"],
                                        item.model + "_custom_end",
                                        $event
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _c("v-select", {
                        attrs: { label: "状态", options: _vm.recordStatusOps },
                        model: {
                          value:
                            _vm.searchParams["conditionFields"].approveStatus,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchParams["conditionFields"],
                              "approveStatus",
                              $$v
                            )
                          },
                          expression:
                            "searchParams['conditionFields'].approveStatus",
                        },
                      }),
                      _c("v-datepicker", {
                        attrs: {
                          label: "填写时间",
                          type: "rangedatetimer",
                          startTime:
                            _vm.searchParams["conditionFields"].beginTime,
                          endTime: _vm.searchParams["conditionFields"].endTime,
                        },
                        on: {
                          "update:startTime": function ($event) {
                            return _vm.$set(
                              _vm.searchParams["conditionFields"],
                              "beginTime",
                              $event
                            )
                          },
                          "update:start-time": function ($event) {
                            return _vm.$set(
                              _vm.searchParams["conditionFields"],
                              "beginTime",
                              $event
                            )
                          },
                          "update:endTime": function ($event) {
                            return _vm.$set(
                              _vm.searchParams["conditionFields"],
                              "endTime",
                              $event
                            )
                          },
                          "update:end-time": function ($event) {
                            return _vm.$set(
                              _vm.searchParams["conditionFields"],
                              "endTime",
                              $event
                            )
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "operateSlot",
                  fn: function (scope) {
                    return [
                      _c("v-button", {
                        attrs: { text: "详情", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.detailData(scope.row)
                          },
                        },
                      }),
                      _vm.userType == 101 &&
                      (_vm.approval == 0 ||
                        ((_vm.approval == 1 || _vm.approval == 2) &&
                          scope.row.approve_status == 3))
                        ? _c("v-button", {
                            attrs: {
                              text: "编辑",
                              type: "text",
                              permission: "resultEdit",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateData(scope.row)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.approval == 1 && scope.row.approve_status === 0
                        ? _c("v-button", {
                            attrs: {
                              text: "审批",
                              permission: "approve",
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.approvalData(scope.row)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.approval == 2
                        ? _c("v-button", {
                            attrs: {
                              text: "审批",
                              permission: "approve",
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.flowWorkApprovalData(scope.row)
                              },
                            },
                          })
                        : _vm._e(),
                      _c("v-button", {
                        attrs: {
                          text: "删除",
                          permission: "resultDelete",
                          type: "text",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteData(scope.row)
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "batchSlot",
                  fn: function (scope) {
                    return [
                      _vm.approval == 1
                        ? _c("v-button", {
                            attrs: {
                              text: "批量审批",
                              permission: "approve",
                              disabled: scope.selectedData.ids.length === 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.batchOperation(1, scope.selectedData)
                              },
                            },
                          })
                        : _vm._e(),
                      _c("v-button", {
                        attrs: {
                          text: "批量删除",
                          permission: "resultDelete",
                          disabled: scope.selectedData.ids.length === 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.batchOperation(2, scope.selectedData)
                          },
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              1805057603
            ),
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "approve-dialog",
            title: _vm.approveTitle,
            width: "750px",
            visible: _vm.approveVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.approveVisible = $event
            },
            closed: _vm.approveDialogClosed,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "approve",
              attrs: { model: _vm.approvalParams, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审批结果：", required: "" } },
                [
                  _c("v-select", {
                    attrs: { options: _vm.approveStatusOps },
                    on: { change: _vm.approveStatusChange },
                    model: {
                      value: _vm.approvalParams.approveStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.approvalParams, "approveStatus", $$v)
                      },
                      expression: "approvalParams.approveStatus",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "审批意见：" } },
                [
                  _c("v-textarea", {
                    model: {
                      value: _vm.approvalParams.approveRemarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.approvalParams, "approveRemarks", $$v)
                      },
                      expression: "approvalParams.approveRemarks",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "button-submit-container" },
                [
                  _c("v-button", {
                    attrs: { text: "提交" },
                    on: { click: _vm.submit },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }