// 查询列表
const queryListURL = `${API_CONFIG.controlBaseURL}formInfov1/pageList`
// 导出列表
const exportListURL = `${API_CONFIG.controlBaseURL}formData/result/export`
// 创建人 select2
const getUserListURL = `${API_CONFIG.butlerBaseURL}manager/user/search/keyword`
// 操作 - 发布
const publishURL = `${API_CONFIG.controlBaseURL}formInfov1/publish`
// 操作 - 停用
const stopURL = `${API_CONFIG.controlBaseURL}formInfov1/stop`
// 操作 - 启用
const startURL = `${API_CONFIG.controlBaseURL}formInfov1/start`
// 操作 - 删除
const deleteURL = `${API_CONFIG.butlerBaseURL}formInfov1/delete`
// 批量操作 - 发布
const batchPublishURL = `${API_CONFIG.butlerBaseURL}formInfov1/batch/publish`
// 批量操作 - 停用
const batchStopURL = `${API_CONFIG.butlerBaseURL}formInfov1/batch/stop`
// 批量操作 - 删除
const batchDeleteURL = `${API_CONFIG.butlerBaseURL}formInfov1/batch/delete`
// 查询设计表单数据
const queryDesignContentURL = `${API_CONFIG.butlerBaseURL}formInfov1/design/content`
// 保存设计表单数据
const designURL = `${API_CONFIG.butlerBaseURL}formInfov1/design`
// 复制表单
const copyFormInfoURL = `${API_CONFIG.butlerBaseURL}formInfov1/copyFormInfo`
// 获取微信分享地址
const queryFormShareWXURL = `${API_CONFIG.controlBaseURL}formInfov1/getFormShareWXUrl`

// 新增基本信息
const createBaseInfoURL = `${API_CONFIG.butlerBaseURL}formInfov1/info/add`
// 更新基本信息
const updateBaseInfoURL = `${API_CONFIG.butlerBaseURL}formInfov1/info/update`
// 查询详情
const queryDetailURL = `${API_CONFIG.butlerBaseURL}formInfov1/info/view`
// 查询项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// 查询项目阶段选项
const getCommunityStageOpsURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 绑定流程 select2
const getFlowKeyURL = `${API_CONFIG.controlBaseURL}workFlowInfo/select2/flowKey`

// 查询列表 - 表单记录
const queryRecodeListURL = `${API_CONFIG.butlerBaseURL}formData/pageList`
// 查询详情 - 表单记录
const queryRecodeDetailURL = `${API_CONFIG.butlerBaseURL}formData/view`
// 查询自定义列
const queryColumnURL = `${API_CONFIG.butlerBaseURL}formInfov1/list/element`
// 操作 - 审核表单记录
const approveRecodeURL = `${API_CONFIG.butlerBaseURL}formDataApprove/approve`
// 操作 - 删除表单记录
const deleteRecodeURL = `${API_CONFIG.butlerBaseURL}formData/delete`
// 批量操作 - 审批
const batchApproveRecodeURL = `${API_CONFIG.butlerBaseURL}formDataApprove/batch/approve`
// 批量操作 - 删除
const batchDeleteRecodeURL = `${API_CONFIG.butlerBaseURL}formData/batch/delete`

// 保存表单数据
const updateFormDataURL = `${API_CONFIG.butlerBaseURL}formData/mydata/update`

// 查询审批状态
const getWorkFlowStatusURL = `${API_CONFIG.controlBaseURL}workFlow/runtime/count/mytask/todo`
// 查询审批流程
const getWorkFlowImgURL = `${API_CONFIG.controlBaseURL}workFlow/his/showImg`
// 查询审批历史
const getApproveHistoryURL = `${API_CONFIG.controlBaseURL}workFlow/his/list/hisActiviti`
// 流程审批
const workFlowApproveURL = `${API_CONFIG.controlBaseURL}workFlow/runtime/process/approve`
// 新增项目
const addCommunityURL = `${API_CONFIG.controlBaseURL}formInfov1/info/addCommunity`
// 保存或更新自定义消息配置
const saveMessageNotifyInfoURL = `${API_CONFIG.butlerBaseURL}formMessage/saveMessageConfig`
// 获取自定义消息配置
const getMessageNotifyInfoUrl = `${API_CONFIG.butlerBaseURL}formMessage/getMessageConfigUser`
export {
  queryListURL,
  exportListURL,
  getUserListURL,
  publishURL,
  stopURL,
  startURL,
  deleteURL,
  batchPublishURL,
  batchStopURL,
  batchDeleteURL,
  queryDesignContentURL,
  designURL,
  copyFormInfoURL,
  queryFormShareWXURL,
  createBaseInfoURL,
  updateBaseInfoURL,
  queryDetailURL,
  getCommunityListURL,
  getCommunityStageOpsURL,
  getFlowKeyURL,
  queryRecodeListURL,
  queryRecodeDetailURL,
  queryColumnURL,
  approveRecodeURL,
  deleteRecodeURL,
  batchApproveRecodeURL,
  batchDeleteRecodeURL,
  updateFormDataURL,
  getWorkFlowStatusURL,
  getWorkFlowImgURL,
  getApproveHistoryURL,
  workFlowApproveURL,
  addCommunityURL,
  saveMessageNotifyInfoURL,
  getMessageNotifyInfoUrl
}
